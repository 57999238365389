<template>
  <div class="dispflex bg-box">
    <div class="login-box">
      <img class="img-login" src="../../assets/img/login.jpg" alt="" />
      <div class="input-box">
        <div class="title">欢迎登录!</div>
        <div class="phone" style="margin-top: 68px">
          <i class="el-icon-user"></i>
          <input
            v-model="ruleForm.username"
            type="text"
            placeholder="请输入您的账号"
          />
        </div>
        <div class="phone">
          <i class="el-icon-key"></i>
          <input
            v-model="ruleForm.password"
            type="password"
            placeholder="请输入密码"
          />
        </div>
        <!-- <div class="show-font">懂设计.更懂你</div> -->
        <div class="btn" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../../assets/api/index";
import { setStore } from "../../assets/js/utils";
export default {
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
    };
  },
  activated() {
    this.ruleForm = {
      username: "",
      password: "",
    };
  },
  created(){
    // console.log(window.location.origin);
  },
  methods: {
    async login() {
      if (this.ruleForm.username === "") {
        this.$message({
          message: "请输入您的账号",
          type: "warning",
        });
        return;
      }

      if (this.ruleForm.password === "") {
        this.$message({
          message: "请输入您的密码",
          type: "warning",
        });
        return;
      }

      let params = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,

      };
      let res = await login(params);
      if (res.code==200) {
        let data = res.data;

        setStore("token", data.token);
        setStore("id", data.id);
        setStore("avatar", data.avatar_url);
        setStore("name", data.nickname);

        this.$router.replace({ path: "/" });
        //登录成功后禁止按enter键再次重新登录
        // document.onkeydown = undefined;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style scoped>
.show-font {
  width: 100%;
  text-align: right;
  color: #1d78f4;
  font-size: 14px;
}
.btn {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #1d78f4;
  color: #fff;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  margin-top: 60px;
}
.title {
  color: #1d78f4;
  font-size: 30px;
  font-weight: 700;
  margin-top: 105px;
}
.input-box {
  flex: 1;
  padding: 50px;
  box-sizing: border-box;
}
.phone {
  display: flex;
  width: 100%;
  height: 34px;
  border: 1px solid #d4d1d1;
  align-items: center;
  overflow: hidden;
  border-radius: 17px;
  margin-top: 34px;
}
.phone i {
  font-size: 20px;
  margin-left: 10px;
  color: #d4d1d1;
}
.phone input {
  flex: 1;
  height: 100%;
  /* background-color: #ccc; */

  padding: 0 10px;
  box-sizing: border-box;
}
.login-box {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 1200px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.img-login {
  width: 800px;
}
</style>